const emitter = {
    methods: {
        dispatch (event, params, componentName) {

            let parent = this.$parent;
            while (parent) {
                if (parent.$options && parent.$options.name === componentName) {
                    return parent.$emit(event, params[0],params[1],params[2]);
                }
                parent = parent.$parent;
            }
        },
        broadcast () {

        }
    }
};

export default emitter;
