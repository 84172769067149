<template>
  <div class="go-date-picker" ref="picker">
    <div class="go-date-picker-popover-drawer" v-if="isShow" @click="$emit('update:isShow',false)">

    </div>
    <div class="go-date-picker-popover" :style="{top:top || top == 0 ? top+'px' : '',bottom: bottom || bottom ==  0 ? bottom+'px' : '' }" v-if="isShow">
      <component
          :is="mode"
          :nowTime="effectiveTime"
          :nowIndex="index"
          :value="value"
          :format-date="formatDate"
          :temp-value.sync="tempValue"
          :mode="mode"
          @mode-change="onModeChange"
      >
      </component>
    </div>
  </div>
</template>

<script>
  // import {getEffectiveTime} from '@/api/api'

  import { cloneDate, getYearMonthDay } from '@/utils/date.js';
import PickerDays from './picker-days';
import PickerMonths from './picker-months';
import PickerYears from './picker-years';
export default {
  name: 'GoDatePicker',
  props: {
    effectiveTime:{
      type:Array,
    },
    index:{
      type:Number,
      default:0
    },
    value: {
      type: Date,
      default: () => new Date()
    },
    isShow: {
      type: Boolean,
      default: false
    },
    top:{
     type: Number,
   },
    bottom:{
      type: Number,
    }
  },
  components: { PickerDays, PickerMonths, PickerYears },
  data () {
    return {
      type:['daysType','weeksType','monthsType'],
      time:[],
      mode: 'picker-days',
      tempValue: cloneDate(this.value),
    };
  },
  computed: {

    formatDate () {
      const [year, month, day] = getYearMonthDay(this.tempValue);
      return { year, month: month + 1, day };
    },
    displayValue: {
      get () {
        const [year, month, day] = getYearMonthDay(this.value);
        return `${year}-${month + 1}-${day}`;
      },
      set (e) {
        if (e?.target?.value) {
          const reg = /(\d+)-(\d+)-(\d+)/;
          const value = e.target.value;
          const matched = value.match(reg);
          if (matched) {
            const [, year, month, day] = matched;
            this.$emit('input', new Date(year, month - 1, day));
          }
        }
      }
    },
  },
  watch: {
    value (val) {
      this.tempValue = cloneDate(val);
    }
  },
  mounted () {
    // document.body.addEventListener('click', this.onClickBody);
  },
  beforeDestroy () {
    document.body.removeEventListener('click', this.onClickBody);
  },
  methods: {
    // async getEffectiveTime(){
    //   try{
    //     let id = this.reportId
    //     console.log(id)
    //     let result =  await getEffectiveTime(id)
    //     console.log(result)
    //     let arr = []
    //     JSON.parse(result.data).map(m => {
    //       arr.push(m.split(' ')[0])
    //     })
    //     this.time = arr
    //     console.log(this.time)
    //   }
    //   catch (e) {
    //     console.log(e)
    //   }
    // },
    onClickBody (e) { // Vue内部会自动帮我们修改this指向
      const { picker } = this.$refs;
      if (picker.contains(e.target)) {
        return;
      }
      this.$emit("update:isShow",false)
    },
    // 42个，每行7个
    onModeChange (mode) {
      setTimeout(() => {
        this.mode = mode;
      });
      // wait all micro tasks execute complete
      // setTimeout(() => {
      //   this.visible = true;
      // });
    },
    changeMonth () {
      const [, month] = getYearMonthDay(this.tempValue);
      const timestamp = this.tempValue.setMonth(month + value);
      this.tempValue = new Date(timestamp);
    },
  },
  created() {
    // this.getEffectiveTime()
    console.log('index'+this.index)
    console.log(this.reportId)
  }
};
</script>
<style lang="less" scoped>
.go-date-picker {
  .go-date-picker-popover-drawer{
    position: fixed;
    width:100%;
    height: 100vh;
    top:0;
    left:0;
    right:0;
    background-color:#cfcfcf;
    opacity: 0.5;
    @media screen and (min-width: 800px){
    display: none;
    }
  }
  .go-date-picker-popover {
    color: #65708c;
    padding: 20px;
    position: fixed;
    right: 3rem;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0 10px 50px 0 rgba(0, 0, 0, .2);
    z-index: 10;
    @media screen and (min-width: 768px){
      top: calc(10% + 10px);
    }
    @media screen and (max-width: 768px){
      right:0;
      padding: 0;
    }
  }
}
</style>
